<template>
	<div
		:class="[
			{ container: shouldUseDefaultContainer, 'section-padding': padding }
		]"
	>
		<slot></slot>
	</div>
</template>

<script setup lang="ts">
import type {
	CssSize,
	ResponsiveSectionPadding
} from '@SHARED/utils/helperTypes';
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { SectionComponent } from '@SHARED/components/sections';

import { getPadding } from '@SHARED/utils/style';

import SectionBlock from '@/components/blocks/SectionBlock.vue';

defineOptions({ name: 'PaddingContainer' });

const styles = useState<WebsiteStyles>('styles');

type PaddingContainerProps = {
	padding?: ResponsiveSectionPadding | null;
	component?: SectionComponent | SectionBlock | 'div' | 'section';
	useFallbackContainer?: boolean;
};

const props = withDefaults(defineProps<PaddingContainerProps>(), {
	padding: null,
	component: 'div',
	useFallbackContainer: true
});

const shouldUseDefaultContainer = computed<boolean>(
	() => !props.padding && props.useFallbackContainer
);

const mobilePaddingTop = computed<CssSize>(() =>
	getPadding('top', props.padding?.mobile)
);

const mobilePaddingRight = computed<CssSize>(() =>
	getPadding('right', props.padding?.mobile)
);

const mobilePaddingBottom = computed<CssSize>(() =>
	getPadding('bottom', props.padding?.mobile)
);

const mobilePaddingLeft = computed<CssSize>(() =>
	getPadding('left', props.padding?.mobile)
);

const desktopPaddingTop = computed<CssSize>(() =>
	getPadding('top', props.padding?.desktop)
);

const desktopPaddingRight = computed<CssSize>(() =>
	getPadding('right', props.padding?.desktop)
);

const desktopPaddingBottom = computed<CssSize>(() =>
	getPadding('bottom', props.padding?.desktop)
);

const desktopPaddingLeft = computed<CssSize>(() =>
	getPadding('left', props.padding?.desktop)
);

const desktopMaxWidth = computed<CssSize>(
	() => styles.value.defaultContainerMaxWidth?.desktop || '1920px'
);

const mobileMaxWidth = computed<CssSize>(
	() => styles.value.defaultContainerMaxWidth?.mobile || '1920px'
);
</script>

<style lang="scss" scoped>
.section-padding {
	padding-top: v-bind(mobilePaddingTop);
	padding-right: v-bind(mobilePaddingRight);
	padding-bottom: v-bind(mobilePaddingBottom);
	padding-left: v-bind(mobilePaddingLeft);
	max-width: v-bind(mobileMaxWidth);

	margin-left: auto;
	margin-right: auto;

	@include screen-up(lg) {
		padding-top: v-bind(desktopPaddingTop);
		padding-right: v-bind(desktopPaddingRight);
		padding-bottom: v-bind(desktopPaddingBottom);
		padding-left: v-bind(desktopPaddingLeft);
		max-width: v-bind(desktopMaxWidth);
	}
}
</style>
